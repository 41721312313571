// extracted by mini-css-extract-plugin
export var b = "shipping-policy-module--b--c959a";
export var blankLineP6 = "shipping-policy-module--blankLineP6--c29fd";
export var captionDiv = "shipping-policy-module--captionDiv--02f01";
export var chooseOrdersLi = "shipping-policy-module--chooseOrdersLi--15ffc";
export var chooseOrdersSelectTheProd = "shipping-policy-module--chooseOrdersSelectTheProd--7bef0";
export var displayB = "shipping-policy-module--displayB--fa999";
export var displayDiv = "shipping-policy-module--displayDiv--0f116";
export var displayDiv1 = "shipping-policy-module--displayDiv1--64ba5";
export var docTemplateDiv = "shipping-policy-module--docTemplateDiv--79375";
export var ifAnyOf = "shipping-policy-module--ifAnyOf--3186d";
export var link = "shipping-policy-module--link--c38a1";
export var managingYourAccount = "shipping-policy-module--managingYourAccount--42d31";
export var pDiv = "shipping-policy-module--pDiv--dede4";
export var pDiv11 = "shipping-policy-module--pDiv11--e7342";
export var pDiv8 = "shipping-policy-module--pDiv8--69e4d";
export var shippingPolicyDiv = "shipping-policy-module--shippingPolicyDiv--21c69";
export var subtitleDiv = "shipping-policy-module--subtitleDiv--7a8c4";
export var subtitleLargeDiv = "shipping-policy-module--subtitleLargeDiv--f5b34";
export var subtitleLargeDiv1 = "shipping-policy-module--subtitleLargeDiv1--f6d5c";
export var textColumnDiv = "shipping-policy-module--textColumnDiv--dd440";
export var textbodyDiv = "shipping-policy-module--textbodyDiv--35683";
export var textbodyDiv2 = "shipping-policy-module--textbodyDiv2--ea8d5";
export var textbodyDiv3 = "shipping-policy-module--textbodyDiv3--e6586";
export var textbodyDiv6 = "shipping-policy-module--textbodyDiv6--525b0";
export var textbodyDiv7 = "shipping-policy-module--textbodyDiv7--5419a";
export var youWillReceive = "shipping-policy-module--youWillReceive--8fda8";