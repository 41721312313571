import * as React from "react";
import * as styles from "./shipping-policy.module.css";

import { FunctionComponent } from "react";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";

const ShippingPolicy: FunctionComponent = () => {
  return (
    <Layout>
    <div className={styles.shippingPolicyDiv}>
      <div className={styles.docTemplateDiv}>
        <div className={styles.textColumnDiv}>
          <div className={styles.subtitleLargeDiv}>
            <b className={styles.displayB}>Shipping and Refund Policy</b>
          </div>
          <div className={styles.subtitleLargeDiv1}>
            <div className={styles.displayDiv}>General Shipping Policies</div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>
              How much does the shipping fee cost?
            </div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                Our flat shipping rate costs $15. We also offer free shipping,
                available on orders over $500. Our shipping fee may depend on
                your location, as not all states share the exact costs for
                delivery. For international orders, extra charges will apply.
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>
              Do you accept orders outside the US?
            </div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                Yes. Our products can be distributed abroad. However, shipping
                timelines may be affected due to restrictions in some countries.
                You will receive shipping estimates at the time of your
                checkout.
              </div>
            </div>
          </div>
          <div className={styles.subtitleLargeDiv1}>
            <div className={styles.displayDiv}>Tracking Your Order</div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>How do I track my order?</div>
          </div>
          <div className={styles.textbodyDiv2}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <p className={styles.youWillReceive}>
                  You will receive SMS or email notifications from time to time.
                  You can also track your package on your order status on our
                  Order Tracking page. Follow the steps below:
                </p>
                <p className={styles.youWillReceive}>&nbsp;</p>
                <ul className={styles.chooseOrdersSelectTheProd}>
                  <li className={styles.chooseOrdersLi}>Choose orders.</li>
                  <li className={styles.chooseOrdersLi}>
                    Select the product you want to track.
                  </li>
                  <li className={styles.chooseOrdersLi}>Choose track order.</li>
                  <li>
                    You can also choose updates to view your delivery status.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>
              When will I receive my package?
            </div>
          </div>
          <div className={styles.textbodyDiv3}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <p className={styles.youWillReceive}>
                  The standard time for receiving orders is 14 business days
                  from the day it is processed. However, if it takes longer than
                  expected, there may be a problem with logistics or the
                  information you provided. Some international packages are not
                  trackable. The reason for this concern may include lacking
                  information on the delivery. Other reasons are as follows:
                </p>
                <p className={styles.youWillReceive}>&nbsp;</p>
                <ul className={styles.chooseOrdersSelectTheProd}>
                  <li className={styles.chooseOrdersLi}>
                    Incomplete order forms
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    Illegal prescriptions
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    Incomplete credit card information (number, name, and
                    expiration date)
                  </li>
                  <li className={styles.chooseOrdersLi}>Unsigned forms</li>
                  <li className={styles.chooseOrdersLi}>
                    Unauthorized transactions
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    Insufficient funds on your credit card
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    Peak periods at holiday time
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    Extreme weather conditions
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>Can I cancel my order?</div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                You can only cancel your orders without charges or penalties if
                the product is not yet prepared. However, it is NOT allowed to
                cancel once the order has been shipped.
              </div>
            </div>
          </div>
          <div className={styles.subtitleLargeDiv1}>
            <div className={styles.displayDiv}>Requesting a Refund</div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>Can I get a refund?</div>
          </div>
          <div className={styles.textbodyDiv2}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <p className={styles.youWillReceive}>
                  Yes. You are eligible for a full refund within 6 months of
                  your purchase. However, if you ask for a replacement or refund
                  after six months, we will not grant your request.
                </p>
                <p className={styles.youWillReceive}>&nbsp;</p>
                <p className={styles.youWillReceive}>
                  Depending on the circumstances, refunds are available for any
                  product defects or issues:
                </p>
                <p className={styles.youWillReceive}>&nbsp;</p>
                <ul className={styles.chooseOrdersSelectTheProd}>
                  <li className={styles.chooseOrdersLi}>
                    Replacement product to the same shipping address
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    Receive a full refund
                  </li>
                  <li>Account credit for the item</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>
              How do I qualify for a refund?
            </div>
          </div>
          <div className={styles.textbodyDiv6}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <p className={styles.youWillReceive}>
                  Our refund services are available at our own discretion. We
                  can only grant you a full refund if it concerns errors or
                  failures on our part. Please note that we strive hard to make
                  sure you are satisfied with your purchases.
                </p>
                <p className={styles.youWillReceive}>
                  Qualifications for a refund include the following:
                </p>
                <p className={styles.youWillReceive}>&nbsp;</p>
                <ul className={styles.chooseOrdersSelectTheProd}>
                  <li className={styles.chooseOrdersLi}>
                    The delivered product is different from the ordered item.
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    The product arrived with a damaged package.
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    The product is defective.
                  </li>
                </ul>
                <p className={styles.youWillReceive}>&nbsp;</p>
                <p className={styles.ifAnyOf}>
                  If any of these factors apply to you, you will receive further
                  instructions from us.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>Use of Your Personal Data</div>
          </div>
          <div className={styles.textbodyDiv7}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                The Global Care Supply reserves the right to use your data for
                the following purposes:
              </div>
            </div>
            <div className={styles.pDiv8}>
              <div className={styles.captionDiv}>
                <ul className={styles.chooseOrdersSelectTheProd}>
                  <li className={styles.chooseOrdersLi}>
                    <b className={styles.managingYourAccount}>
                      Managing your account
                    </b>
                    <span>
                      {" "}
                   - We will use your data to access your account, orders and  shipment information.
                    </span>
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    <b className={styles.managingYourAccount}>
                      Contact and updating
                    </b>
                    <span className={styles.managingYourAccount}>
                      {" "}
                      – We will use your information to contact you through
                      email, telephone calls, SMS, or other forms of electronic
                      communication. We also provide updates related to our
                      service’s functionalities and product implementations.
                    </span>
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    <b className={styles.managingYourAccount}>
                      Performing your contract
                    </b>
                    <span className={styles.managingYourAccount}>
                      {" "}
                      – This includes the development, compliance, and
                      undertaking of your purchases with our products.
                    </span>
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    <b className={styles.managingYourAccount}>
                      Notifying with offers
                    </b>
                    <span className={styles.managingYourAccount}>
                      {" "}
                      – As part of our service, we occasionally inform you of
                      special offers, goods, services, and events in which you
                      can participate. However, you can opt not to receive such
                      information or details.
                    </span>
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    <b className={styles.managingYourAccount}>Other purposes</b>
                    <span className={styles.managingYourAccount}>
                      {" "}
                      – We may use your personal information for other purposes
                      related to our company’s promotional campaigns, service
                      improvement, product and services, and marketing for a
                      better purchase experience.
                    </span>
                  </li>
                  <li className={styles.chooseOrdersLi}>
                    <b
                      className={styles.managingYourAccount}
                    >{`With Your consent: `}</b>
                    <span className={styles.managingYourAccount}>
                      We may disclose your personal information for any other
                      purpose. For more information, please refer to the <a className={styles.link} href="/privacy-policy"> privacy policy page.</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <b>{`Note: `}</b>
                <span>
                  We can only disclose your personal information with your
                  consent.
                </span>
              </div>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>
              Disclosure of Your Personal Data
            </div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                Global Care Supply shall not disclose your personal information
                for purposes other than those for which it was collected. This
                involves transactions and important adherence to the following:
              </div>
            </div>
          </div>
          <div className={styles.pDiv11}>
            <div className={styles.captionDiv}>
              <ul className={styles.chooseOrdersSelectTheProd}>
                <li className={styles.chooseOrdersLi}>
                  <b className={styles.managingYourAccount}>
                    Law enforcement –
                  </b>
                  <b className={styles.b}>{` `}</b>
                  <span>
                    Global Care Supply shall not use or disclose your personal
                    information except for legal obligations or as required by
                    law. Our company may be required to disclose your
                    information if it’s subject to public authorities or
                    government agencies.
                  </span>
                </li>
              </ul>
              <p className={styles.blankLineP6}>
                <span>&nbsp;</span>
              </p>
              <ul className={styles.chooseOrdersSelectTheProd}>
                <li>
                  <b className={styles.managingYourAccount}>
                    Other legal requirements -
                  </b>
                  <b className={styles.b}>{` `}</b>
                  <span>
                    Global Care Supply may disclose your personal data if it is
                    necessary to comply with a legal obligation, defend the
                    company’s rights, investigate possible wrongdoings within
                    the service, protect the personal safety of the users, and
                    safeguard against legal liability.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.subtitleDiv}>
            <div className={styles.displayDiv1}>How do I return a product?</div>
          </div>
          <div className={styles.textbodyDiv}>
            <div className={styles.pDiv}>
              <div className={styles.captionDiv}>
                <p className={styles.youWillReceive}>
                  We may not accept product returns. If you order medical
                  devices and they do not work, keep the lot number and its
                  expiry date. Please report this to our customer support and
                  ask for guidance on how to handle defective orders.
                </p>
                <p className={styles.ifAnyOf}>
                  If you have more questions or concerns about this policy, do
                  not hesitate to contact us for more information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default ShippingPolicy;

export const Head = () => (
  <SEO title="Global Care Supply | Shipping Policy" description="" />
)
